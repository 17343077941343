"use client";

import React, { createContext, useContext, useState, useEffect } from "react";

// Define the theme configuration interface
export interface ThemeConfig {
  // Brutalist design options
  enableShadows: boolean;
  enableThickBorders: boolean;
  borderWidth: number; // in pixels
  shadowOffset: number; // in pixels
}

// Default configuration
const defaultConfig: ThemeConfig = {
  enableShadows: true,
  enableThickBorders: true,
  borderWidth: 2,
  shadowOffset: 4,
};

// Create the context
interface ThemeConfigContextType {
  config: ThemeConfig;
  updateConfig: (newConfig: Partial<ThemeConfig>) => void;
  resetConfig: () => void;
}

const ThemeConfigContext = createContext<ThemeConfigContextType | undefined>(undefined);

// Provider component
export function ThemeConfigProvider({ children }: { children: React.ReactNode }) {
  // Initialize state with default config
  const [config, setConfig] = useState<ThemeConfig>(defaultConfig);

  // Load config from localStorage on mount (client-side only)
  useEffect(() => {
    const savedConfig = localStorage.getItem("theme-config");
    if (savedConfig) {
      try {
        const parsedConfig = JSON.parse(savedConfig);
        setConfig({ ...defaultConfig, ...parsedConfig });
      } catch (error) {
        console.error("Failed to parse saved theme config:", error);
      }
    }
  }, []);

  // Update CSS variables when config changes
  useEffect(() => {
    // Only run on the client
    if (typeof window === "undefined") return;

    const root = document.documentElement;

    // Update border width
    root.style.setProperty("--border-width", config.enableThickBorders ? `${config.borderWidth}px` : "1px");

    // Update shadow
    if (config.enableShadows) {
      root.style.setProperty("--box-shadow-x", `${config.shadowOffset}px`);
      root.style.setProperty("--box-shadow-y", `${config.shadowOffset}px`);
      root.style.setProperty("--reverse-box-shadow-x", `-${config.shadowOffset}px`);
      root.style.setProperty("--reverse-box-shadow-y", `-${config.shadowOffset}px`);
      root.style.setProperty("--shadow", `var(--box-shadow-x) var(--box-shadow-y) 0px 0px var(--border)`);
    } else {
      root.style.setProperty("--shadow", "none");
    }

    // Save to localStorage
    localStorage.setItem("theme-config", JSON.stringify(config));
  }, [config]);

  // Update config function
  const updateConfig = (newConfig: Partial<ThemeConfig>) => {
    setConfig((prevConfig) => ({ ...prevConfig, ...newConfig }));
  };

  // Reset config function
  const resetConfig = () => {
    setConfig(defaultConfig);
  };

  return <ThemeConfigContext.Provider value={{ config, updateConfig, resetConfig }}>{children}</ThemeConfigContext.Provider>;
}

// Custom hook to use the theme config
export function useThemeConfig() {
  const context = useContext(ThemeConfigContext);
  if (context === undefined) {
    throw new Error("useThemeConfig must be used within a ThemeConfigProvider");
  }
  return context;
}
